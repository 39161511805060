import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #fff;
  height: 759px;
  left: 0px;
  top: 0px;
  border-radius: 0px;

  @media screen and (min-width: 567px) {
    height: 800px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url("./images/laptop-bitcoin.svg") no-repeat;
    background-position: top right;
    background-size: contain;
  }

  @media screen and (min-width: 1200px) {
    background: url("./images/laptop-bitcoin.svg") no-repeat;
    background-position: top right;
    background-size: contain;
  }
`;

export const Hero = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 0 5.393743257820928vw;
  direction: ${(props) => (props.langDirection === "rtl" ? "rtl" : "ltr")};

  @media screen and (min-width: 1200px) {
    width: 50%;
    margin-top: 50px;
    padding-left: 5.393743257820928vw;
  }
`;

export const HeroTitle = styled.p`
  margin: 0;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 53px;
  letter-spacing: -0.2px;
  color: #121212;

  @media screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const HeroContent = styled.div`
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #4d4d4d;

  p {
    margin: 0;
  }

  span {
    font-weight: 700;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    line-height: 17px;
  }
`;

export const DivGrid = styled.div`
  margin-top: 12px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const DivGrid2 = styled.div`
  margin-top: 12px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const CardWrapper = styled.div`
  background: #ff931e29;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 12px;

  p {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.2px;
    color: #121212;
    margin: 0;

    @media screen and (max-width: 768px) {
      font-size: 12px;
      line-height: 17px;
    }
  }
`;
