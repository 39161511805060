import { Select as MenuSelect, MenuItem as MenuOption } from "@mui/material";
import styled from "styled-components";

export const NavWrapper = styled.div`
  width: 100%;
  padding: 44px 5.208333333333333vw 0 5.208333333333333vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
`;

export const Logo = styled.img`
  .logo {
    height: 52px;
    width: 157.31px;
  }

  @media only screen and (max-width: 980px) {
    width: 96px;
  }
`;

export const Select = styled.select`
  text-align: left;
  border: none;
  background: transparent;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  border: none;
  outline: none;
  .flag-img,
  img {
    width: 15px;
  }
  option {
    display: block;
    text-align: left;
    direction: ltr;
    position: relative;
    ::before {
      content: "";
      position: absolute;
      background-color: red;
      width: 25px;
      height: 25px;
      top: 0;
      left: 0;
    }
  }
`;

export const FlagWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const FlagIcon = styled.img`
  width: 25px;
  height: 25px;
`;

export const CustomSelect = styled(MenuSelect)`
  background-color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #4d4d4d;
  padding: 0 16px;

  div {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  & > .MuiSvgIcon-root {
    right: ${(props) =>
      props.textDirection === "rtl" ? "unset" : "7px"} !important;
    left: ${(props) =>
      props.textDirection === "rtl" ? "7px" : "unset"} !important;
  }

  & > .MuiInputBase-input {
    padding-left: ${(props) =>
      props.textDirection === "rtl" ? "32px" : "0px"} !important;
    padding-right: ${(props) =>
      props.textDirection === "rtl" ? "0px" : "32px"} !important;
  }

  fieldset {
    border: none;
  }
`;

export const CustomMenuItem = styled(MenuOption)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  text-align: left;
`;
