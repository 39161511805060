import React from "react";
import BoxFrame from "../BoxFrame";
import { FrameTitle, FrameContent, FrameCard } from "../style";
import { Wrapper } from "./style";
import { Trans, useTranslation } from "react-i18next";

const Custody = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <BoxFrame alignImage="right" dataImage="./images/custody.svg">
        <FrameTitle>{t("custody.title")}</FrameTitle>
        <FrameContent>
          <p>{t("custody.desc1")}</p>
          <FrameCard>
            <img src="./images/Money_Liner.svg" alt="not-found" />
            <p>
              <Trans>{t("custody.card.desc1")}</Trans>
            </p>
          </FrameCard>
          <FrameCard>
            <img src="./images/MoneyLiner2.svg" alt="not-found" />
            <p>
              <Trans>{t("custody.card.desc2")}</Trans>
            </p>
          </FrameCard>
          <p>{t("custody.desc2")}</p>
        </FrameContent>
      </BoxFrame>
    </Wrapper>
  );
};

export default Custody;
