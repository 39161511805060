import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { CustomSelect, CustomMenuItem, FlagIcon, FlagWrapper } from "./style";

const LanguageSelector = () => {
  const [language, setLanguage] = useState("en");
  const { i18n } = useTranslation();

  const languageSelectHandler = (event) => {
    setLanguage(event.target.value);
    i18n.changeLanguage(event.target.value).then(() => {
      // document.body.dir = i18n.dir();
    });
  };

  return (
    <>
      <FlagWrapper>
        <CustomSelect value={language} onChange={languageSelectHandler} textDirection={i18n.dir()}>
          <CustomMenuItem value="en">
            <FlagIcon alt="flag" className="fluid-icon" src="/images/Eng.svg" />
            ENG
          </CustomMenuItem>
{/*          <CustomMenuItem value="ar">
            <FlagIcon alt="flag" className="fluid-icon" src="/images/Arb.svg" />
            Arb
          </CustomMenuItem>
          <CustomMenuItem value="fr">
            <FlagIcon alt="flag" className="fluid-icon" src="/images/Fr.svg" />
            Fr
          </CustomMenuItem>
          <CustomMenuItem value="he">
            <FlagIcon alt="flag" className="fluid-icon" src="/images/Flag_of_Israel.svg" />
            Heb
          </CustomMenuItem>
          */}
          <CustomMenuItem value="hi">
            <FlagIcon alt="flag" className="fluid-icon" src="/images/IN.svg" />
            HIN
          </CustomMenuItem>
          <CustomMenuItem value="kk">
            <FlagIcon alt="flag" className="fluid-icon" src="/images/KZ.svg" />
            KAZ
          </CustomMenuItem>
          <CustomMenuItem value="br">
            <FlagIcon alt="flag" className="fluid-icon" src="/images/BR.svg" />
            POR
          </CustomMenuItem>
          <CustomMenuItem value="tai">
            <FlagIcon alt="flag" className="fluid-icon" src="/images/TH.svg" />
            TAI
          </CustomMenuItem>
          <CustomMenuItem value="uz">
            <FlagIcon alt="flag" className="fluid-icon" src="/images/UZ.svg" />
            UZB
          </CustomMenuItem>
        </CustomSelect>
      </FlagWrapper>
    </>
  );
};

export default LanguageSelector;
