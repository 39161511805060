import React from "react";
import { CardWrapper } from "./style";

const Card = ({ imageUrl, content }) => {
  return (
    <CardWrapper>
      <img src={imageUrl} alt="" />
      <p className="card-content">{content}</p>
    </CardWrapper>
  );
};

export default Card;
