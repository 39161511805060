import React from "react";
import { DivGrid, DivGrid2, Hero, HeroContent, HeroTitle, Wrapper } from "./style";
import Card from "./Card";
import Navbar from "../Navbar";
import { useTranslation } from "react-i18next";

const DigitalCurrency = () => {
  const { i18n, t } = useTranslation();

  return (
    <Wrapper>
      <Navbar />
      <Hero langDirection={i18n.dir()}>
        <HeroTitle>{t("heroSection.title")}</HeroTitle>
        <HeroContent>
          <p>{t("heroSection.description")}</p>
          <br />
          <span>{t("heroSection.description2")}</span>
          <DivGrid>
            <Card imageUrl="/images/CrytoCompanyLiner.svg" content={t("heroSection.cards.desc1")} />
            <Card imageUrl="/images/Liner.svg" content={t("heroSection.cards.desc2")} />
            <Card imageUrl="/images/MoneyLiner.svg" content={t("heroSection.cards.desc3")} />
          </DivGrid>
          <DivGrid2>
            <Card imageUrl="/images/MoneyLiner(1).svg" content={t("heroSection.cards.desc4")} />
            <Card imageUrl="/images/MoneyLiner(2).svg" content={t("heroSection.cards.desc5")} />
          </DivGrid2>
        </HeroContent>
      </Hero>
    </Wrapper>
  );
};

export default DigitalCurrency;
