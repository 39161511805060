import styled from "styled-components";

export const FrameTitle = styled.p`
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  letter-spacing: -0.2px;
  color: #121212;

  @media screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const FrameContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4d4d4d;

  @media screen and (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const FrameCard = styled.div`
  background: #ffffff;
  border-radius: 24px;
  margin-bottom: 16px;
  padding: 16px 24px;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 14px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #121212;

  span {
    font-weight: 700;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
  }
`;
