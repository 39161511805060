import React from "react";
import BoxFrame from "../BoxFrame";
import { FrameTitle, FrameContent } from "../style";
import { Wrapper } from "./style";
import { useTranslation } from "react-i18next";

const Payments = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <BoxFrame alignImage="right" dataImage="./images/Payments.svg">
        <FrameTitle>{t("payments.title")}</FrameTitle>
        <FrameContent>
          <p>{t("payments.desc1")}</p>
          <p>{t("payments.desc2")}</p>
        </FrameContent>
      </BoxFrame>
    </Wrapper>
  );
};

export default Payments;
