import React from "react";
import styled from "styled-components";
import Access from "../Components/Access";
import Custody from "../Components/Custody";
import DigitalCurrency from "../Components/DigitalCurrency";
import Payments from "../Components/Payments";
import TransactionalData from "../Components/TransactionalData";
import TransactionReversibility from "../Components/TransactionReversibility";

const Home = () => {
  return (
    <>
      <DigitalCurrency />
      <Container>
        <Custody />
        <Access />
        <TransactionReversibility />
        <TransactionalData />
        <Payments />
      </Container>
    </>
  );
};

const Container = styled.div`
  display: block;
  width: 100%;
`;

export default Home;
