import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const BoxFrame = (props) => {
  const { i18n } = useTranslation();

  return (
    <Grid container columns={{ xs: 1, sm: 6, md: 12 }}>
      {props.alignImage === "left" ? (
        <>
          <Grid xs={1} sm={6} md={6} display="flex" justifyContent="start" alignItems="end">
            <Item>
              <img src={`${props.dataImage}`} alt="img" />
            </Item>
          </Grid>
          <Grid xs={1} sm={6} md={6} sx={{ m: "auto", direction: i18n.dir() }}>
            <Item>{props.children}</Item>
          </Grid>
        </>
      ) : (
        <>
          <Grid xs={1} sm={6} md={6} sx={{ m: "auto", direction: i18n.dir() }}>
            <Item>{props.children}</Item>
          </Grid>
          <Grid xs={1} sm={6} md={6} display="flex" justifyContent="end" alignItems="end">
            <Item>
              <img src={`${props.dataImage}`} alt="img" />
            </Item>
          </Grid>
        </>
      )}
    </Grid>
  );
};

const Item = styled.div`
  img {
    display: block;
    max-width: 100%;
    height: auto;
    /* padding: 0 3.33vw; */
  }

  @media screen and (min-width: 900px) {
    img {
      /* padding: 0; */
    }
  }
`;

export default BoxFrame;
