import React from "react";
import { Logo } from "../Navbar/style";
import { Container, FooterContent, SocialWrapper, Wrapper } from "./style.js";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { i18n, t } = useTranslation();

  return (
    <Container langDirection={i18n.dir()}>
      <Logo src="/images/Logo.svg" />
      <SocialWrapper>
        <a href={"https://www.linkedin.com/company/digidoe"} target={"_blank"}> <img src="/images/linkedin.svg" alt="linkedin-logo" /></a>
        <Wrapper>
          <img src="/images/sms.svg" alt="email-logo" />
          <a href={"mailto:info@digidoe.com"}><p>{t("footer.socialInfo.email")}</p></a>
        </Wrapper>
      </SocialWrapper>
      <FooterContent>
        <p>{t("footer.content")}</p>
        <p>{t("footer.copyright")}</p>
      </FooterContent>
    </Container>
  );
};

export default Footer;
