import React from "react";
import LanguageSelector from "../Navbar/LanguageSelector";
import { Logo } from "../Navbar/style";
import { NavWrapper } from "./style";

const NewNavbar = () => {
  return (
    <NavWrapper>
      <Logo src="/images/Logo.svg" />
      <LanguageSelector />
    </NavWrapper>
  );
};

export default NewNavbar;
